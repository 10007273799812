import * as React from "react"
import TitlePage from "../components/home/TitlePage"
import LibraryList from "../components/home/LibraryList"
import WhyNunguFlow from "../components/home/WhyNunguFlow"
import Features from "../components/home/Features"
import Layout from "../components/layout"

import Seo from "../components/seo"

const IndexPage = () => (
  <>
    <Seo title="Home" />
    <Layout>
      <TitlePage />
      <LibraryList />
      <WhyNunguFlow />
      <Features />
    </Layout>
  </>
)

export default IndexPage
