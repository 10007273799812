import { Button, Container, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import WebsiteBanner from "../../images/website_banner.png"

const banner = {
  width: "100%",
  height: "auto",
  position: "relative",
  backgroundColor: "white",
}

const content = {
  width: "100%",
  height: "auto",
  maxWidth: "1400px",
  margin: "100px auto 100px auto",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  position: "relative",
  zIndex: "10",
}

const bannerTitleWrapper = {
  width: { xs: "100%", md: "40%" },
}

const conRight = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: { xs: "0%", md: "40%" },
}

const TitlePage = () => {
  return (
    <Container>
      <Box sx={banner}>
        <Box sx={content}>
          <Box sx={bannerTitleWrapper}>
            <Typography variant="h3" gutterBottom="true">
              Develop software without{" "}
              <span
                style={{
                  textDecorationLine: "line-through",
                  textDecorationColor: "red",
                  textDecorationStyle: "solid",
                }}
              >
                coding
              </span>
            </Typography>

            <Typography variant="subtitle1" gutterBottom="true" paragraph="true">
              Dive deeper into your research without having to write a massive
              codebase
            </Typography>

            <Button variant="contained" color="primary">
              Download
            </Button>
          </Box>
          <Box sx={conRight}>
            <img src={WebsiteBanner} width="100%" />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default TitlePage
