import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import styled from "styled-components"

const logoListWrapper = {
  width: "100%",
  height: "250px",
  overflow: "hidden",
  backgroundColor: "white",
}

const logoListStyle = { width: "9616px", overflowX: "hidden" }

const logoListRow2Style = {
  transform: "translate(52.5px, -50px)",
}

const logoListRow3Style = { transform: "translate(0px, -100px)" }

const imageStyle = { display: "block", margin: "auto" }

const imageContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}

const LibraryList = () => {
  const controls = useAnimation()
  const listData1 = [
    {
      src: "tf.svg",
      width: "50px",
    },
    {
      src: "numpy.svg",
      width: "60px",
    },
    {
      src: "keras.svg",
      width: "50px",
    },
    {
      src: "aws.svg",
      width: "60px",
    },
    {
      src: "azure.svg",
      width: "60px",
    },
  ]

  const listData2 = [
    {
      src: "gcp.svg",
      width: "50px",
    },
    {
      src: "postgressql.svg",
      width: "50px",
    },
    {
      src: "pytorch.svg",
      width: "35px",
    },
    {
      src: "opencv.svg",
      width: "40px",
    },
    {
      src: "pcl.png",
      width: "40px",
    },
  ]

  const listData3 = [
    {
      src: "pandas.svg",
      width: "50px",
    },
    {
      src: "scipy.svg",
      width: "50px",
    },
    {
      src: "theano.svg",
      width: "35px",
    },
    {
      src: "matplotlib.svg",
      width: "50px",
    },
    {
      src: "beautifulsoup.png",
      width: "60px",
    },
  ]

  useEffect(() => {
    controls.start("visible")
  }, [])

  return (
    <div style={logoListWrapper}>
      <motion.div
        animate={{ x: -4000 }}
        transition={{
          type: "tween",
          ease: "linear",
          duration: 100,
          repeat: "Infinity",
        }}
      >
        <div style={logoListStyle}>
          <div>
            {Array.from({ length: 91 }).map((_, i) => (
              <Box>
                <div id="imagebox" style={imageContainer}>
                  <img
                    src={
                      require("../../images/libraries/" +
                        listData1[i % listData1.length].src).default
                    }
                    width={listData1[i % listData1.length].width}
                    style={imageStyle}
                  />
                </div>
              </Box>
            ))}
          </div>
          <div style={logoListRow2Style}>
            {Array.from({ length: 91 }).map((_, i) => (
              <Box>
                <div id="imagebox" style={imageContainer}>
                  <img
                    src={
                      require("../../images/libraries/" +
                        listData2[i % listData2.length].src).default
                    }
                    width={listData2[i % listData2.length].width}
                    style={imageStyle}
                  />
                </div>
              </Box>
            ))}
          </div>
          <div style={logoListRow3Style}>
            {Array.from({ length: 91 }).map((_, i) => (
              <Box>
                <div id="imagebox" style={imageContainer}>
                  <img
                    src={
                      require("../../images/libraries/" +
                        listData3[i % listData3.length].src).default
                    }
                    width={listData3[i % listData3.length].width}
                    style={imageStyle}
                  />
                </div>
              </Box>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

const Box = styled(motion.div)`
  margin: 20px;
  display: inline-block;
  height: 65px;
  width: 65px;
  border-radius: 10px;
  transform: rotate(-45deg);
  box-shadow: 6px 6px 8px 0 rgba(0, 0, 0, 0.25),
    -4px -4px 6px 0 rgba(255, 255, 255, 0.3);
  text-align: center;
`

export default LibraryList
