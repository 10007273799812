import React from "react"
import { Typography, Grid, Box } from "@mui/material"

const featureWrapper = {
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "1400px",
  bgcolor: "white",
}

const feature = {
  justifyContent: "space-around",
  alignItems: "center",
  mx: "30px",
  my: "15px",
  px: "60px",
  py: "15px",
  display: "inline-block",
  borderRadius: "10px",
  bgcolor: "white",
}

const ImageBlock = props => {
  return (
    <Grid item xs={12} md={6}>
      <img
        style={{
          display: "block",
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        src={props.image}
        alt="image"
      ></img>
    </Grid>
  )
}

const TextBlock = props => {
  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h3" gutterBottom="true">
        {props.heading}
      </Typography>
      <Typography variant="body1" paragraph="true" gutterBottom="true">
        {props.body}
      </Typography>
    </Grid>
  )
}

export default props => {
  return (
    <Box sx={featureWrapper}>
      <Box sx={feature}>
        <Grid
          container
          alignItems="center"
          direction={props.textPosition === "left" ? "row-reverse" : "row"}
          spacing={6}
        >
          <ImageBlock image={props.image} />
          <TextBlock heading={props.heading} body={props.body} />
        </Grid>
      </Box>
    </Box>
  )
}
