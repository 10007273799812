import React from "react"
import ImageAndTextBlock from "./ImageAndTextBlock"
import { Container, Box } from "@mui/material"

const features = {
  "& > :nth-child(5n+1)": {
    "& h3": { color: "#FF7744" }, //orange
  },
  "& > :nth-child(5n+2)": {
    "& h3": { color: "#1199ee" }, //blue
  },
  "& > :nth-child(5n+3)": {
    "& h3": { color: "#bb66cc" }, //magenta
  },
  "& > :nth-child(5n+4)": {
    "& h3": { color: "#66bb66" }, //green
  },
  "& > :nth-child(5n+5)": {
    "& h3": { color: "#4455bb" }, //dark blue  
  },
}

const Features = () => {
  return (
    <Container>
      <Box sx={features}>
        <ImageAndTextBlock
          textPosition="right"
          image={require("../../images/LibraryManager.png").default}
          heading="Powerful Library"
          body="Access millions of python libraries as model blocks. One of the biggest challenge of using model based design, is that the libraries were always a few years behind the actual research. With Nungu you can import new libraries as and when they are released online by the researcher, as you would do when you write a python code. Nungu’s powerful library manager will let you import any python code or library, into your model."
        />
        <ImageAndTextBlock
          textPosition="left"
          image={require("../../images/Scope.png").default}
          heading="Powerful Visualisation"
          body="Visualise and test your model as soon as you prototype. Nungu is packed with a library of visualisation tools to visualise your data."
        />
        <ImageAndTextBlock
          textPosition="right"
          image={require("../../images/LibraryManager.png").default}
          heading="Simulation"
          body="Simulate and test your model with powerful pre-defined input functions, before deployment. Need more control, use the “function block” to create custom input functions for simulation."
        />
        <ImageAndTextBlock
          textPosition="left"
          image={require("../../images/LibraryManager.png").default}
          heading="Code Generation"
          body="Need your code, click the “Generate code” button. Nungu will write it for you based on your model."
        />
        <ImageAndTextBlock
          textPosition="right"
          image={require("../../images/LibraryManager.png").default}
          heading="Self Documentation"
          body="Your model is your design document. After your design is complete, simply download your model as a PDF."
        />
      </Box>
    </Container>
  )
}

export default Features
